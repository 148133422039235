import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'

import Logo from '../src/pages/Functionality/Logo'
import AdminListPage from '../src/pages/Functionality/SuperAdmin/AdminTable'

import { AccessProtected } from './ProtectedRoute'
import MasterProtectedRoute from './MasterProtectedRoute'
import AddAssessment from './components/AddAssessment'
import EditProfile from './features/EditLearners'
import UploadReport from './features/UploadReport'
import ManageResults from './pages/Program/ManageResults'

import AnalyticsIndex from './pages/Analytics/index'
import AssessmentLevel from './pages/Analytics/reports/AssessmentCharts/AssessmentLevel'
import DepartmentLevel from './pages/Analytics/reports/Department'
import ProgramLevel from './pages/Analytics/reports/Program'
import LearnerLevel from './pages/Analytics/reports/Learners/Learner'
import OverAllLevel from './pages/Analytics/reports/Overall'

import AdminDashboard from './pages/Dashboard/AdminDashboard'
import Layout from './pages/Dashboard/Layout'

import Error401 from './pages/Functionality/Error401'
import CreateSadmin from './pages/Functionality/SuperAdmin/CreateSadmin'
import WhiteLabeling from './pages/Functionality/WhiteLabeling'
import MyCalendar from './pages/Functionality/calendar'

import Learner_list from './pages/Learners/Learner_list'
import Learners from './pages/Learners/Learners'
import ManageLearner from './pages/Learners/ManageLearner'
import Login from './pages/Login/Login'
import MasterAdmin from './pages/Login/master_admin_login'
import SuperAdminLogin from './pages/Login/super_admin_login'
import NotFound from './pages/NotFound'
import Program from './pages/Program'
import AssignProgram from './pages/Program/AssignProgram'
import CreateModule from './pages/Program/CreateModule'
import CreateProgram from './pages/Program/CreateProgram'
import Settings from './pages/Settings'
import AddUser from './pages/Settings/AddUser'
import AddUsersBU from './pages/Settings/AddUsersBU'
import EditUserAdmin from './pages/Settings/EditUserAdmin'
import EditUserBU from './pages/Settings/EditUserBU'
import ManageBU from './pages/Settings/ManageBU'
import ManageUsers from './pages/Settings/ManageUsers'
import ProfileDetails from './pages/Settings/ProfileDetails'
import UserSettings from './pages/Settings/UserSettings'
import UserSettingsBU from './pages/Settings/UserSettingsBU'
import UserForm from './pages/Settings/Users'
import EditPermissions from './pages/Settings/Permissions'

interface AppRoutesProps {
  isLoggedIn: boolean
  setIsLoggedIn: (isLoggedIn: boolean) => void
}

const AppRoutes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const handleLogin = () => {
    setIsLoggedIn(true)
  }

  const handleLogout = () => {
    setIsLoggedIn(false)
  }
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/super_admin" element={<SuperAdminLogin />} />
      <Route path="/master_admin" element={<MasterAdmin />} />
      <Route path="/" element={<Layout />}>
        <Route element={<MasterProtectedRoute />}>
          <Route path="/Add_Super_Admin" element={<CreateSadmin />} />
          <Route path="/admin-list" element={<AdminListPage />} />
        </Route>

        <Route index path="/dashboard" element={<AdminDashboard />} />
        <Route path="/manage_results" element={<ManageResults />} />
        <Route
          element={<AccessProtected requires={['manage_learners_page']} />}>
          <Route path="/learners" element={<Learners />}>
            <Route path="learners-list" element={<Learner_list />} />
            <Route path="manageLearners" element={<ManageLearner />} />
            <Route path="editLearner" element={<EditProfile />} />
          </Route>
        </Route>

        <Route
          element={
            <AccessProtected
              requires={[
                'manage_program_page',
                'assign_program_to_learners_page',
              ]}
            />
          }>
          <Route path="/program" element={<Program />}>
            <Route path="create" element={<CreateProgram />} />
            <Route path=":programId/createModule" element={<CreateModule />} />
            <Route path=":programId/:moduleId" element={<CreateModule />} />
            <Route path=":programId/details" element={<CreateProgram />} />
            <Route path=":programId/assign" element={<AssignProgram />} />
            {/* <Route path=":programId/analytics" element={<NotFound />} /> */}
            <Route index element={<NotFound />} />
          </Route>
        </Route>

        <Route
          element={<AccessProtected requires={['manage_assessment_page']} />}>
          <Route path="/addassessmentId" element={<AddAssessment />} />
        </Route>

        <Route element={<AccessProtected requires={['manage_reports_page']} />}>
          <Route path="/upload-report" element={<UploadReport />} />
        </Route>

        <Route
          element={<AccessProtected requires={['manage_analytics_page']} />}>
          <Route path="/analytics" element={<AnalyticsIndex />}>
            <Route index path="overall" element={<OverAllLevel />} />
            <Route path="departmentLevel" element={<DepartmentLevel />} />
            <Route path="programLevel" element={<ProgramLevel />} />
            <Route path="assessmentLevel" element={<AssessmentLevel />} />

            <Route
              element={
                <AccessProtected requires={['view_learner_level_analytics']}>
                  <UserSettingsBU />
                </AccessProtected>
              }>
              <Route path="learnerlevel">
                <Route index element={<LearnerLevel />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route
          element={<AccessProtected requires={['manage_settings_page']} />}>
          <Route path="/settings" element={<Settings />}>
            <Route path="profile" element={<ProfileDetails />} />
            <Route path="icons" element={<Logo />} />

            <Route
              element={
                <AccessProtected requires={['manage_bu']}>
                  <UserSettingsBU />
                </AccessProtected>
              }>
              <Route path="manageBU">
                <Route index element={<ManageBU />} />
                <Route path="addBU" element={<AddUsersBU />} />
                <Route path="editBU" element={<EditUserBU />} />
              </Route>
            </Route>

            <Route
              element={
                <AccessProtected requires={['manage_users']}>
                  <UserSettingsBU />
                </AccessProtected>
              }>
              <Route path="manageUsers">
                <Route index element={<ManageUsers />} />
                <Route path="addUsers">
                  <Route index element={<UserForm />} />
                  <Route path="edit" element={<EditPermissions />} />
                </Route>
                <Route path="editAdmin" element={<EditUserAdmin />} />
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<AccessProtected requires={['white_labelling']} />}>
          <Route path="/request_whitelabeling" element={<WhiteLabeling />} />
        </Route>

        <Route element={<AccessProtected requires={['view_calendar']} />}>
          <Route path="/calendar" element={<MyCalendar />} />
        </Route>

        <Route path="/401" element={<Error401 />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
