import React, { memo, useMemo, useCallback } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import * as XLSX from "xlsx";
import useData from "src/hooks/useData/useData";
import { Report } from "../../report.interface";

interface ReportTableProps {
  data: Report[];
  learnerDetails: any;
}

interface FlattenedReport {
  _id: string;
  program_name: string;
  program_id: string;
  assessment_name: string;
  total_score?: number;
  attempt_number: string | number;
  completed_on: string;
  report_url: string;
  code_playback: string;
  [key: string]: any; // Dynamic keys for module scores
}

const LearnerReportTable: React.FC<ReportTableProps> = memo(({ data = [], learnerDetails = [] }) => {
  const { user } = useData();

  // Flatten report data to match the table format
  const flattenReportData = useCallback((reports: Report[]): FlattenedReport[] => {
    const allScoreKeys = new Set<string>();

    reports.forEach((report) => {
      report.report_data.forEach((attempt) => {
        if (attempt.score) {
          Object.keys(attempt.score).forEach((key) => allScoreKeys.add(key));
        }
      });
    });

    return reports.flatMap((report) =>
      report.report_data.map((attempt) => {
        const flatData: FlattenedReport = {
          _id: report._id,
          program_name: report.program_name,
          program_id: report.program_id,
          assessment_name: attempt["Project Name"],
          total_score: attempt.totalScore,
          attempt_number: attempt.attempt_number || "1",
          completed_on: attempt["Participant completed date and time"] || "-",
          report_url: attempt["Report URL"] || "-",
          code_playback: attempt.tag5 || "-",
          score: attempt.score || {},
        };

        // Add dynamic score keys
        allScoreKeys.forEach((key) => {
          flatData[key] = attempt.score ? attempt.score[key] : "-";
        });

        return flatData;
      })
    );
  }, []);

  const flattenedData = useMemo(() => flattenReportData(data), [data, flattenReportData]);

  // Define table columns dynamically
  const columns: TableColumn<FlattenedReport>[] = useMemo(() => {
    const baseColumns: TableColumn<FlattenedReport>[] = [
      { 
        name: "S.No", 
        selector: (_row, index) => (index !== undefined ? index + 1 : "-"), 
        sortable: true 
      },      
      { name: "Program Name", selector: (row) => row.program_name || "N/A", sortable: true },
      { name: "Assessment Name", selector: (row) => row.assessment_name || "N/A", sortable: true },
      {
        name: "Completed On",
        selector: (row) =>
          row.completed_on !== "-" ? new Date(row.completed_on).toLocaleString() : "N/A",
        sortable: true,
      },
      { name: "Attempt Number", selector: (row) => row.attempt_number || "N/A", sortable: true },
      { name: "Total Score", selector: (row) => row.total_score || "N/A", sortable: true },
      {
        name: "Report URL",
        cell: (row) =>
          row.report_url !== "-" ? (
            <a href={row.report_url} target="_blank" rel="noopener noreferrer">
              View Report
            </a>
          ) : (
            "N/A"
          ),
        ignoreRowClick: true,
      },
      {
        name: "Code Playback",
        cell: (row) =>
          row.code_playback !== "-" ? (
            <a href={row.code_playback} target="_blank" rel="noopener noreferrer">
              View Code Playback
            </a>
          ) : (
            "N/A"
          ),
        ignoreRowClick: true,
      },
    ];

    // Add dynamic score columns based on available keys in the dataset
    const dynamicScoreColumns: TableColumn<FlattenedReport>[] = [];
    if (flattenedData.length > 0) {
      const firstRow = flattenedData[0];
      Object.keys(firstRow).forEach((key) => {
        if (key.startsWith("Module") || key.includes("Score")) {
          dynamicScoreColumns.push({
            name: key,
            selector: (row) => row[key] || "-",
            sortable: true,
          });
        }
      });
    }

    return [...baseColumns, ...dynamicScoreColumns];
  }, [flattenedData]);

  // Convert data to Excel file
  const convertJsonToFile = useCallback(() => {
    if (flattenedData.length === 0) {
      console.warn("No data available for export");
      return;
    }

    const excelData = flattenedData.map((item) => ({
      ...learnerDetails,
      ...item,
    }));

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "learners");
    XLSX.writeFile(wb, "learnerlist.xlsx");
  }, [flattenedData]);

  return (
    <div style={{
      width: '1050px'
    }}>
      <DataTable
        columns={columns}
        data={flattenedData}
        pagination
        highlightOnHover
        striped
        responsive
        defaultSortFieldId="s_no"
      />
      {user?.permission?.includes("download_reports") && (
        <button
          className="my-4 bg-green-600 hover:bg-green-700 text-white py-2 px-4 rounded"
          onClick={convertJsonToFile}
        >
          Download Report
        </button>
      )}
    </div>
  );
});

export default LearnerReportTable;
