import React, { useCallback}from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import * as XLSX from 'xlsx'

// Define the type for a single learner
interface Learner {
  _id: string;
  learner_name: string;
  college_reg_no: string;
  email_id: string;
  department: string;
  department_section: string;
  passed_out: number;
  programs_assigned: { program_id: string }[];
}

// Define the type for a report's data
interface ReportData {
  UniqueId: number;
  attempt_number: string;
  totalScore: number;
  module_id: string;
}

// Define the type for a report entry
interface Report {
  _id: string;
  learner_id: string;
  program_id: string;
  learnerReports:any;
  report_data: ReportData[];
}

// Define the type for a module
interface Module {
  _id: string;
  module_name: string;
}

// Define the type for a row in the table
interface TableRow {
  id: number;
  learnerName: string;
  collegeRegNo: string;
  email: string;
  department: string;
  section: string;
  batch: number;
  programName: string;
  attemptCount: number;
  programTotalScore: number;
  [key: string]: number | string;
}

// Define props for the component
interface Props {
  learners: Learner[];
  reports: Report[];
  selectedProgram: any;
  selectedProgramId: any;
}

const AssessmentReportTable: React.FC<Props> = ({ learners, reports, selectedProgram, selectedProgramId }) => {
  const programName = selectedProgram?.program_name || "Unknown Program";

  // Get module list from the program
  const modules = selectedProgram?.modules_list || [];

  // Filter learners assigned to the given program
  const filteredLearners = learners.filter((learner) =>
    learner.programs_assigned.some((p) => p.program_id === selectedProgramId)
  );

  // Construct table data
  const tableData: TableRow[] = filteredLearners.map((learner, index) => {
    const learnerReports = reports.filter(
      (report) => report.learner_id === learner._id && report.program_id === selectedProgramId
    );

    // Find latest attempt for each module by module_id
    let moduleScores: Record<string, number> = {};
    modules.forEach((module: any) => {
      const latestAttempt = learnerReports
        .flatMap((report) => report.report_data)
        .filter((data) => data.module_id === module._id) // Match module_id
        .sort((a, b) => Number(b.attempt_number) - Number(a.attempt_number)) // Get latest attempt
        .pop();

      moduleScores[module.module_name] = latestAttempt ? latestAttempt.totalScore : 0; // Default to 0 if no attempt
    });

    return {
      id: index + 1,
      learnerName: learner.learner_name,
      collegeRegNo: learner.college_reg_no,
      email: learner.email_id,
      department: learner.department,
      section: learner.department_section,
      batch: learner.passed_out,
      programName: programName,
      attemptCount: learnerReports.length > 0 ? learnerReports[0].report_data.length : 0,
      programTotalScore:  Object.keys(moduleScores).length > 0 ?
         Object.values(moduleScores).reduce((a, b) => a + b, 0)/Object.keys(moduleScores).length : 0, // Sum of all module scores
      ...moduleScores,
    };
  });

  // Define table columns dynamically from module names
  const columns: TableColumn<TableRow>[] = [
    { name: "SL. No", selector: (row) => row.id, sortable: true },
    { name: "Learner Name", selector: (row) => row.learnerName, sortable: true },
    { name: "College Register No", selector: (row) => row.collegeRegNo, sortable: true },
    { name: "Email ID", selector: (row) => row.email, sortable: true },
    { name: "Department", selector: (row) => row.department, sortable: true },
    { name: "Section", selector: (row) => row.section, sortable: true },
    { name: "Batch", selector: (row) => row.batch, sortable: true },
    { name: "Program Name", selector: (row) => row.programName, sortable: true },
    { name: "Attempt Count", selector: (row) => row.attemptCount, sortable: true },
    { name: "Program Total Score", selector: (row) => row.programTotalScore, sortable: true },
    ...modules.map((module: any) => ({
      name: module.module_name, // Use module name as column header
      selector: (row: any) => row[module.module_name], // Select score for that module
      sortable: true,
    })),
  ];

   const convertJsonToFile = useCallback(() => {
       const excelData = tableData.map((item) => ({
        ...item
      }));
      var ws = XLSX.utils.json_to_sheet(excelData);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'learners');
      XLSX.writeFile(wb, 'ProgramLearnerList.xlsx');
    }, [tableData]);

  return (
    <div>
    <div style={{
      width:'1040px'
    }}>
      <h2>Assessment Report</h2>
      <DataTable
        columns={columns}
        data={tableData}
        pagination
        highlightOnHover
        striped
        responsive
      />
      
    </div>
    <button
          className="my-8 bg-[#009000] hover:bg-[#276749] w-[11rem] text-white p-2 rounded-md"
          onClick={convertJsonToFile}>
          Download ScoreSheet
        </button>
    </div>
  );
};

export default AssessmentReportTable;
