import axios from 'axios'
import userData from 'src/hooks/useData/useData'

const isLocalhost = Boolean(window.location.hostname === 'localhost')
export const API_URL = isLocalhost
  ? 'http://localhost:5000'
  : 'https://admin.skillfirstlabs.com/api'

console.log('API_URL', API_URL)
const fetchProgramsList = async function () {
  const data = await fetch(`${API_URL}/allprograms`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
    .then(async response => {
      const result = await response.json()
      console.log(result)
      return result.all_programs
    })
    .catch(error => console.log(error))
}
const insertPrograms = async function (data) {
  return await axios.post(`${API_URL}/insert_program`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}
const insertModules = async function (data) {
  return await axios.post(`${API_URL}/insert_module`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}

const insertActivity = async function (data) {
  console.log(data, 'insert')

  return await axios.post(`${API_URL}/insert_activity`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}

export const deleteProgram = async function (data) {
  return await axios.post(`${API_URL}/delete_program`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}

export const deleteModule = async function (data) {
  return await axios.post(`${API_URL}/delete_program`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}

export const deleteActivity = async function (data) {
  return await axios.post(`${API_URL}/delete_program`, data, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
}

const fetchLearners = async function () {
  const response = await axios.get(`${API_URL}/fetchlearners`, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  })
  if (!response.ok) {
  }
  return response.data.learner_list
}

// TODO - working on the login to be return
const fetchProgramData = async function (programId) {
  return fetch(`${API_URL}/fetchprogram`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify({ programId }),
  })
    .then(async response => {
      console.log(response)
      if (!response.ok) {
        return response
      }
      const result = await response.json()
      console.log(result)
      return result.program
    })
    .catch(error => {
      console.log(error)
      return error
    })
}

export function isDateLessThan(date1, date2) {
  // This is a reusable function that compares dates by ignoring its respective times associated to it
  // compared one after the other year then month and finally day also for equal in day

  date1 = new Date(date1)
  date2 = new Date(date2)
  // Compare the years
  if (date1.getFullYear() < date2.getFullYear()) {
    return true
  } else if (date1.getFullYear() > date2.getFullYear()) {
    return false
  }

  // Compare the months
  if (date1.getMonth() < date2.getMonth()) {
    return true
  } else if (date1.getMonth() > date2.getMonth()) {
    return false
  }

  // Compare the days
  if (date1.getDate() <= date2.getDate()) {
    return true
  }

  // If years, months, and days are equal, return false
  return false
}

let entirePermission = [
  'assign_learners',
  'unassign_learners',
  'view_program_analytics',
  'view_program_settings',
  'modify_program_settings',
  'view_program',
  'create_program',
  'edit_program',
  'delete_program',
  'review_assignment',
  'view_assessment',
  'create_assessment',
  'delete_assessment',
  'enroll_learners',
  'manage_access',
  'unenroll_learners',
  'edit_profile',
  'global_settings',
  'white_labelling',
  'email_templates',
  'notice_board',
  'assessment_engine_selection',
  'view_analytics',
  'download_analytics_reports',
  'add_users',
  'remove_users',
  'manage_users',
]

const AssociatedPermissions = [
  { path: '/program/create', permission: 'create_program' },
  { path: '/program/', permission: 'edit_program' },
  { path: '/learners/learners-list', permission: 'manage_learners_page' },
  { path: '/settings/', permission: 'global_settings' },
  { path: '/settings/manageBU/addBU', permission: 'add_bu' },
  { path: '/settings/manageUsers', permission: 'manage_users' },
  { path: '/analytics', permission: 'manage_analytics_page' },
  { path: '/addassessmentId', permission: 'manage_assessment_page' },
]

export const PermissionCheck = path => {
  // const {user} = userData()
  // console.log(path)
  const permissionList = AssociatedPermissions.find(per => per.path == path)
  // console.log(permissionList)
  if (!permissionList) {
    return false
  }
  const permission = JSON.parse(localStorage.getItem('user')).permission
  // console.log(permission)
  if (permission.includes(permissionList.permission)) {
    return true
  }
  return false
}

export {
  fetchProgramsList,
  insertPrograms,
  insertModules,
  fetchProgramData,
  fetchLearners,
  insertActivity,
}
