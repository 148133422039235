import React, { useState } from 'react';
import moment from 'moment';
import { programSchema } from 'src/pages/Program/program.interface';

interface Activity {
  type: string;
  status: number;
  data: {
    no_of_attempt?: string;
    test_id?: string;
    test_name?: string;
    activity_url?: string;
    activity_description?: string;
    activity_name?: string;
    activity_start_date: string;
    activity_end_date: string;
  };
  order: number;
  _id: string;
}

interface Module {
  module_name: string;
  module_description: string;
  module_start_date: string;
  module_end_date: string;
  order: number;
  activity_list: Activity[];
  _id: string;
}

interface ProgramData {
  program_name: string;
  program_short_description: string;
  program_description: string;
  program_start_date: string;
  program_end_date: string;
  modules_list: Module[];
  _id: string;
}

interface Program {
  _id: string;
  program_name: string;
  program_short_description: string;
  program_start_date: string;
  program_end_date: string;
  modules_list: any[];
  organization_ids: any[];
  end_date?: string;
  status: number;
  created_at: string;
}

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: ProgramData;
  onSubmit: (formData: ProgramData) => void;
  programsList: Program[]; // Changed to array for proper checks
}

const ProgramModal: React.FC<ModalProps> = ({ isOpen, onClose, data, onSubmit, programsList }) => {
  const initialDate = moment().format('YYYY-MM-DDTHH:mm');
  const [formData, setFormData] = useState<ProgramData>({
    program_name: data.program_name + " (1)",
    program_short_description: data.program_short_description,
    program_description: data.program_description,
    program_start_date: initialDate,
    program_end_date: initialDate,
    modules_list: data.modules_list.map(module => ({
      module_name: module.module_name,
      module_description: module.module_description,
      module_start_date: initialDate,
      module_end_date: initialDate,
      order: module.order,
      activity_list: module.activity_list.map(activity => ({
        ...activity,
        activity_start_date: initialDate,
        activity_end_date: initialDate,
      })),
      _id: module._id,
    })),
    _id: data._id,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index?: number, moduleIndex?: number) => {
    const { name, value } = e.target;

    if (moduleIndex !== undefined) {
      const updatedModules = [...formData.modules_list];
      updatedModules[moduleIndex] = { ...updatedModules[moduleIndex], [name]: value };
      setFormData({ ...formData, modules_list: updatedModules });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = () => {
    console.log(programsList)
    const nameExists = programsList.some(program => program.program_name === formData.program_name);

    if (nameExists) {
      alert("A program with this name already exists. Please choose a different name.");
      return;
    }

    onSubmit(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-[80vw] max-h-[90vh] overflow-auto text-black">
        <h2 className="text-xl font-bold mb-4">Edit Program</h2>
        <div>
          <label className="block mb-1">Program Name</label>
          <input
            type="text"
            name="program_name"
            value={formData.program_name}
            onChange={handleChange}
            className="border rounded w-full p-2 mb-4"
          />
        </div>
        <div>
          <label className="block mb-1">Short Description</label>
          <textarea
            name="program_short_description"
            value={formData.program_short_description}
            onChange={handleChange}
            className="border rounded w-full p-2 mb-4"
          />
        </div>
        <div>
          <label className="block mb-1">Description</label>
          <textarea
            name="program_description"
            value={formData.program_description}
            onChange={handleChange}
            className="border rounded w-full p-2 mb-4"
          />
        </div>
        <div>
          <label className="block mb-1">Start Date</label>
          <input
            type="datetime-local"
            name="program_start_date"
            value={formData.program_start_date}
            onChange={handleChange}
            className="border rounded w-full p-2 mb-4"
          />
        </div>
        <div>
          <label className="block mb-1">End Date</label>
          <input
            type="datetime-local"
            name="program_end_date"
            value={formData.program_end_date}
            onChange={handleChange}
            className="border rounded w-full p-2 mb-4"
          />
        </div>
        {formData.modules_list.map((module, index) => (
          <div key={index} className="mb-4">
            <h3 className="font-semibold mb-2">Module {index + 1}</h3>
            <div>
              <label className="block mb-1">Module Name</label>
              <input
                type="text"
                name="module_name"
                value={module.module_name}
                onChange={(e) => handleChange(e, index)}
                className="border rounded w-full p-2 mb-4"
              />
            </div>
            <div>
              <label className="block mb-1">Module Description</label>
              <textarea
                name="module_description"
                value={module.module_description}
                onChange={(e) => handleChange(e, index)}
                className="border rounded w-full p-2 mb-4"
              />
            </div>
            <div>
              <label className="block mb-1">Module Start Date</label>
              <input
                type="datetime-local"
                name="module_start_date"
                value={module.module_start_date}
                onChange={(e) => handleChange(e, index)}
                className="border rounded w-full p-2 mb-4"
              />
            </div>
            <div>
              <label className="block mb-1">Module End Date</label>
              <input
                type="datetime-local"
                name="module_end_date"
                value={module.module_end_date}
                onChange={(e) => handleChange(e, index)}
                className="border rounded w-full p-2 mb-4"
              />
            </div>
            {module.activity_list.map((activity, activityIndex) => (
              <div key={activityIndex} className="mb-4">
                <h4 className="font-semibold mb-2">Activity {activityIndex + 1} - {activity.type}</h4>
                <div>
                  <label className="block mb-1">Activity Start Date</label>
                  <input
                    type="datetime-local"
                    name="activity_start_date"
                    value={activity.data.activity_start_date}
                    onChange={(e) => handleChange(e, activityIndex, index)}
                    className="border rounded w-full p-2 mb-4"
                  />
                </div>
                <div>
                  <label className="block mb-1">Activity End Date</label>
                  <input
                    type="datetime-local"
                    name="activity_end_date"
                    value={activity.data.activity_end_date}
                    onChange={(e) => handleChange(e, activityIndex, index)}
                    className="border rounded w-full p-2 mb-4"
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
        <div className="flex justify-between">
          <button onClick={handleSubmit} className="bg-blue-500 rounded-lg text-white rounded px-4 py-2">Submit</button>
          <button onClick={onClose} className="bg-gray-300 rounded-lg rounded px-4 py-2">Close</button>
        </div>
      </div>
    </div>
  );
};

export default ProgramModal;
