import React, { useState, useCallback, useMemo } from 'react'
import {
  IReporAssessmentLevelResponse,
  IReportFilter,
  SortChartType,
} from '../../report.interface'
import { API_URL } from '../../../../services'
import ReportFilter from '../../components/ReportFilter'
import {
  defaultPieData,
  defaultColumnChartResponse,
  removeEmptyValues,
} from '../../utils'
import BoxPlot from '../../components/BoxPlot/BoxPlot'
import StackedBarChart from '../../components/ColumnChart/StackedColumnChart'
import PieChart from '../../components/PieChart/PieChart'
import BarChart from '../../components/BarChart'
import RadioButton from '../../components/RadioButton'

const formFields: IReportFilter = {
  organization_id: { value: '' },
  passed_out: { value: '' },
  program_id: { value: '' },
  department: { value: '' },
  assessments: { value: '', required: true },
}

const defaultResponse: IReporAssessmentLevelResponse = {
  department_avg_std_dev: {},
  organization_avg_std_dev: {},
  department_performance: {
    average: [],
    max: [],
    latest: [],
  },
  organization_performance: {
    average: [],
    max: [],
    latest: [],
  },
  percentile_data: {
    average: defaultPieData,
    max: defaultPieData,
    latest: defaultPieData,
  },
  section_analytics: {
    average: [],
    max: [],
    latest: [],
  },
  section_wise_percentile: {
    average: defaultColumnChartResponse,
    max: defaultColumnChartResponse,
    latest: defaultColumnChartResponse,
  },
}

const AssessmentStatusChart = () => {
  const [selected, setSelected] = useState<SortChartType>('average')
  const [selectedFilters, setSelectedFilters] = useState<IReportFilter>(
    formFields
  )

  const [reportData, setReportData] = useState<IReporAssessmentLevelResponse>(
    defaultResponse
  )

  const handleResetEvent = () => {
    // Reset the chart data
    setSelectedFilters(formFields)
  }

  const handleFilterChange = (
    filterName: keyof IReportFilter,
    filterValue: string
  ) => {
    setSelectedFilters(prevFilters => {
      const prevObj = prevFilters[filterName]
      const updatedFilters = {
        ...prevFilters,
        [filterName]: { ...prevObj, value: filterValue },
      }

      return updatedFilters
    })
  }

  // Extract the labels and series based on the selected value (average, max, latest)
  const assessmentData = useMemo(() => {
    console.log('selectedValue ', selected)
    console.log(selected, reportData.organization_performance[selected])
    const a = {
      department_performance: reportData.department_performance[selected],
      organization_performance: reportData.organization_performance[selected],
      percentile_data: reportData.percentile_data[selected],
      section_analytics: reportData.section_analytics[selected],
      section_wise_percentile: reportData.section_wise_percentile[selected],
    }
    console.log(a)
    return a
  }, [selected, reportData])

  const fetchReportData = useCallback(async () => {
    try {
      const query = new URLSearchParams(removeEmptyValues(selectedFilters))
      const response = await fetch(
        `${API_URL}/super_admin_assessment_level/?${query}`
      )

      const result = await response.json()
      setReportData(result)
    } catch (error) {
      console.error('Error fetching super_admin_assessment_level:', error)
    }
  }, [selectedFilters])

  return (
    <div className="w-full">
      <ReportFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        notifyApplyEvent={fetchReportData}
        notifyResetEvent={handleResetEvent}
      />
      <RadioButton selected={selected} handleChange={setSelected} />
      <BoxPlot
        title="Assessment Performance Analytics (OWA)"
        chartData={assessmentData.organization_performance}
      />
      <div className="flex justify-between w-full">
        <div className="w-1/2">
          <BoxPlot
            title={'Assessment Performance Analytics (DWA)'}
            chartData={assessmentData.department_performance}
          />
        </div>
        <div className="w-1/2">
          <PieChart
            chartData={assessmentData.percentile_data}
            title="Percentile Analysis"
          />
        </div>
      </div>

      <StackedBarChart
        chartData={assessmentData.section_wise_percentile}
        title="Section wise Percentile Analysis"
      />
    </div>
  )
}

export default AssessmentStatusChart
