import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
type userValue = {
  company_name: string
  username: string
  profile_pic: string
  company_logo: string
}

const userData = () => {
  const navigate = useNavigate()
  const [user, setUser] = useState<any>('')
  const [token, setToken] = useState('')

  useEffect(() => {
    const storedUser = localStorage.getItem('user')
    const storedToken = localStorage.getItem('token')
    setToken(storedToken ? storedToken : '')
    setUser(storedUser ? JSON.parse(storedUser) : null)
  }, [])

  const storeData = (userData: any, tokenData: string) => {
    localStorage.setItem('user', JSON.stringify(userData))
    localStorage.setItem('token', tokenData)
    const expiryDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
    localStorage.setItem('expiryDate', expiryDate.toISOString())
    console.log(userData)
    setUser(userData)
  }

  const removeUser = () => {
    if (!user) {
      navigate('/login')
    } else {
      const { role } = user
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('expiryDate')
      localStorage.removeItem('learners')
      setToken('')
      setUser('')
      if (role && role === 'super_admin') {
        navigate('/super_admin')
      } else {
        navigate('/login')
      }
    }
  }

  return { user, token, storeData, removeUser }
}

export default userData
