import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ChildNavProps } from '../interfaces/program'
import CustomSidebar from 'src/pages/Settings/CustomSideBar'

const ChildNav = ({ childNavData }: ChildNavProps) => {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <div className="flex flex-col gap-5">
      <div>
        <button
          onClick={() => {
            navigate(-1)
          }}
          className="bg-[#202e59] hover:bg-[#2c5282] text-white rounded-md px-8 py-2 mr-2">
          Back
        </button>
      </div>
      <div className="px-2 w-[20rem]">
        <CustomSidebar />
      </div>
      {childNavData && (
        <div className="flex p-2">
          <ul className="flex flex-col text-center text-black bg-white rounded-md w-[14rem]">
            {childNavData.map(navItem => (
              <li
                className={`m-1 px-2 rounded-lg p-2 ${
                  location.pathname.startsWith(navItem.path)
                    ? 'bg-[#202e59] text-white'
                    : ''
                }`}
                key={navItem.path}>
                <Link to={navItem.path}>{navItem.linkText}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ChildNav
