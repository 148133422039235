import React from 'react'
import { Navigate, RouteProps, Route, Outlet } from 'react-router-dom'

interface MasterProtectedRouteProps {
  role: string
}

const MasterProtectedRoute = () => {
  const userData = localStorage.getItem('user')
  const userRole =
    userData !== null && JSON.parse(userData)?.role
      ? JSON.parse(userData)?.role
      : ''
  const isMasterAdmin = userRole === 'master_admin'
  return isMasterAdmin ? <Outlet /> : <Navigate to="/login" />
}
// export const AccessProtected = ({ requires }: any) => {
//   const userData = localStorage.getItem('user')
//   const rights =
//     userData !== null && JSON.parse(userData)?.permission
//       ? JSON.parse(userData)?.permission
//       : []
//   if (!rights) {
//     return <Navigate to="/login" />
//   } else {
//     const hasAnyOne = requires.some((value: any) => rights.includes(value))
//     if (hasAnyOne) {
//       return <Outlet />
//     } else {
//       return <Navigate to="/dashboard" />
//     }
//   }
// }

export default MasterProtectedRoute
