import React, { useState, useEffect, useCallback } from 'react'
import type { UserFormField } from './settings.interface'
import { checkValidData } from './utils/Validate'
import { useNavigate } from 'react-router-dom'
import { API_URL } from '../../services/index'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { toast } from 'react-toastify'

export interface FormErrors {
  [key: string]: string
}
interface UserData {
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[] // Assuming permission is an array of strings
  profile_pic: string
  role: string
  social_profile: any[] // You can define a specific type if needed
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
  // Add other properties if applicable
}
interface Organization {
  _id: string
  valid_from: string
  email_id: string
  organization_name: string
  valid_to: string
  status: number
  role: string
  country: string
  state: string
  // Add other properties as needed
}
const userDataString = localStorage.getItem('user')
const userData: UserData | null = userDataString
  ? JSON.parse(userDataString)
  : null

export const USER_FORM_FIELDS: UserFormField[] = [
  {
    label: 'Role',
    type: 'select',
    value: 'role',
    required: true,
  },
  {
    label: 'Admin Name',
    type: 'text',
    value: 'adminName',
    required: true,
  },
  {
    label: 'BU/Organization Name',
    type: 'select',
    value: 'buOrgName',
    required: true,
  },
  {
    label: 'Email id',
    type: 'email',
    value: 'email',
    required: true,
  },
  {
    label: 'Username',
    type: 'text',
    value: 'username',
    required: true,
  },
  {
    label: 'Status',
    type: 'radio',
    value: 'status',
    required: true,
  },
  {
    label: 'Alternate email',
    type: 'email',
    value: 'altEmail',
    required: false,
  },
  {
    label: 'Mobile no',
    type: 'mobileNo',
    value: 'mobileNo',
    required: true,
  },
  {
    label: 'Country',
    type: 'text',
    value: 'country',
    required: true,
  },
  {
    label: 'State',
    type: 'text',
    value: 'state',
    required: true,
  },
  {
    label: 'City',
    type: 'text',
    value: 'city',
    required: true,
  },
  {
    label: 'Address',
    type: 'text',
    value: 'address',
    required: false,
  },
  {
    label: 'Website',
    type: 'url',
    value: 'website',
    required: true,
  },
  {
    label: 'Linkedin url',
    type: 'url',
    value: 'linkedinUrl',
    required: false,
  },
  {
    label: 'Valid From',
    type: 'date',
    value: 'validFrom',
    required: true,
  },
  {
    label: 'Valid To',
    type: 'date',
    value: 'validTo',
    required: true,
  },
]

const UserForm = () => {
  const navigate = useNavigate()
  const super_admin_start = JSON.parse(localStorage.getItem('user')!).valid_from
  const super_admin_end = JSON.parse(localStorage.getItem('user')!).valid_to
  const [formData, setFormData] = useState({
    role: '',
    adminId: '',
    adminName: '',
    buOrgId: '',
    buOrgName: '',
    email: '',
    status: '',
    altEmail: '',
    mobileNo: '',
    country: '',
    state: '',
    city: '',
    address: '',
    username: '',
    website: '',
    linkedinUrl: '',
    validFrom: '',
    validTo: '',
    logo: '',
  })
  const urlSearchParams = window.location.href.split('?')[1] // Get the search parameters string
  const searchParamsArray = urlSearchParams.split('&') // Split into key-value pairs
  const token = localStorage.getItem('token')
  let params = new URL(location.href)
  let praseParams = params.searchParams
  const searchData: any = {} // Initialize an object to store the search data

  searchParamsArray.forEach(param => {
    const [key, value] = param.split('=') // Split each pair into key and value
    searchData[key] = decodeURIComponent(value) // Decode URI components and store in the object
  })

  const [errors, setErrors] = useState<FormErrors>({})
  const saveUserDetails = async () => {
    console.log(formData)
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/sub_admin/update`,
        data: { formData, _id: searchData?._id }, // FormData goes here
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      const data = response.status
      if (data == 200) {
        navigate(
          '/settings/manageUsers/addUsers/edit?_id=' + praseParams.get('_id')
        )
      } else {
        console.log(response.data)
      }
    } catch (error) {
      toast.error('Error fetching AdminUser')
      console.error('Error fetching AdminUser:', error)
    }
  }

  const handleSubmit = () => {
    const validationErrors = checkValidData(formData)
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      void saveUserDetails()
    } else {
      toast.error("Form data is not valid'")
      console.log('Form data is not valid')
    }
  }
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const fetchUserDetails = async () => {
    try {
      // Make an authorized POST request using Axios
      const response = await axios(`${API_URL}/sub_admin/FetchAll`, {
        method: 'POST', // Use POST method
        data: { sub_admin: searchData._id },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      const data = response.data
      const selectedUser = data.data.find((item: any) => (item._id === searchData._id))
      if (response.status == 200 && data && selectedUser) {
        setFormData({
          ...formData,
          ['adminName']: selectedUser?.admin_name,
          ['buOrgName']: selectedUser?.organization_id,
          ['role']: selectedUser?.role,
          ['email']: selectedUser?.email_id,
          ['mobileNo']: selectedUser?.mobile_no,
          ['country']: selectedUser?.country,
          ['status']: selectedUser?.status == 1 ? 'true' : 'false',
          ['state']: selectedUser?.state,
          ['city']: selectedUser?.city,
          ['address']: selectedUser?.address,
          ['username']: selectedUser?.username,
          ['website']: selectedUser?.social_profile.join(''),
          ['linkedinUrl']: selectedUser?.linkedin_url,
          ['validFrom']: selectedUser?.valid_from,
          ['validTo']: selectedUser?.valid_to,
        })
      } // Assuming the response contains an array of organizations
      else {
        console.log(response.data)
      }
    } catch (error) {
      toast.error('Error fetching User Details')
      console.error('Error fetching organizations:', error)
    }
  }
  const fetchOrganizationList = useCallback(async () => {
    try {
      // Get the token from localStorage

      // Make an authorized POST request using Axios
      const response = await axios(`${API_URL}/organization/FetchAll`, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      const data = response.data
      if (
        data &&
        data.organizations.length !== 0 &&
        Object.keys(organizations).length == 0
      ) {
        setOrganizations(data.organizations)
      }
    } catch (error) {
      toast.error('Error fetching organizations')
      console.error('Error fetching organizations:', error)
    }
  }, [])
  useEffect(() => {
    // fetchOrganizationList()
    if (formData.adminName == '') {
      fetchUserDetails()
      fetchOrganizationList()
    }
  }, [])
  return (
    <div className="max-w-4xl mx-auto p-4 bg-gray-400 w-[45rem] border border-gray-300 rounded-lg shadow-lg">
      <div className="flex flex-col gap-4">
        {USER_FORM_FIELDS.map(field => (
          <div
            key={field.value}
            className="flex flex-col gap-2 p-4 border-b border-gray-200">
            <label
              htmlFor={field.value}
              className="font-semibold text-gray-700">
              {field.label}{' '}
              {field.required && <span className="text-red-500">*</span>}
            </label>
            <div className="relative">
              {(field.type === 'text' ||
                field.type === 'number' ||
                field.type === 'url' ||
                field.type === 'password' ||
                field.type === 'mobileNo' ||
                field.type === 'email') && (
                <input
                  type={field.type}
                  id={field.value}
                  name={field.value}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={formData[field.value] || ''}
                  onChange={event =>
                    setFormData({
                      ...formData,
                      [field.value]: event.target.value,
                    })
                  }
                />
              )}
              {field.type === 'date' && (
                <DatePicker
                  selected={
                    formData[field.value]
                      ? new Date(formData[field.value])
                      : null
                  }
                  onChange={date => {
                    if (date) {
                      let dateUsingKnownTime
                      if (field.value === 'validFrom') {
                        dateUsingKnownTime = new Date(date)
                      } else if (field.value === 'validTo') {
                        date.setHours(23)
                        date.setMinutes(59)
                        dateUsingKnownTime = date
                      }

                      if (dateUsingKnownTime) {
                        setFormData({
                          ...formData,
                          [field.value]: dateUsingKnownTime.toISOString(),
                        })
                      }
                    }
                  }}
                  disabled={field.value === 'validFrom'}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              )}

              {field.type === 'select' && field.value === 'role' && (
                <select
                  id={field.value}
                  name={field.value}
                  value={formData[field.value] || ''}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={event =>
                    setFormData({
                      ...formData,
                      [field.value]: event.target.value,
                    })
                  }>
                  <option value="">Select Role</option>
                  <option value="admin">Admin</option>
                  <option value="manager">Manager</option>
                </select>
              )}
              {field.type === 'radio' && field.value === 'status' && (
                <div className="flex gap-4">
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      id={`${field.value}-active`}
                      name={field.value}
                      value="true"
                      checked={formData[field.value] === 'true'}
                      className="text-blue-600 focus:ring-blue-500"
                      onChange={event =>
                        setFormData({
                          ...formData,
                          [field.value]: event.target.value,
                        })
                      }
                    />
                    Active
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="radio"
                      id={`${field.value}-inactive`}
                      name={field.value}
                      value="false"
                      checked={formData[field.value] === 'false'}
                      className="text-blue-600 focus:ring-blue-500"
                      onChange={event =>
                        setFormData({
                          ...formData,
                          [field.value]: event.target.value,
                        })
                      }
                    />
                    Inactive
                  </label>
                </div>
              )}
              {field.type === 'select' && field.value === 'buOrgName' && (
                <select
                  id={field.value}
                  name={field.value}
                  value={formData[field.value] || ''}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  onChange={event => {
                    if (event.target.value === '') {
                      setFormData({
                        ...formData,
                        [field.value]: event.target.value,
                      })
                      return
                    }
                    let organization = organizations.find(
                      org => org._id === event.target.value
                    )
                    setFormData({
                      ...formData,
                      [field.value]: event.target.value,
                    })
                  }}>
                  <option value="">~SELECT~</option>
                  {organizations &&
                    organizations.length > 0 &&
                    organizations
                      .filter(item => item.status)
                      .map(org => (
                        <option key={org._id} value={org._id}>
                          {org.organization_name}
                        </option>
                      ))}
                </select>
              )}
            </div>
            {errors[field.value] && (
              <span className="text-red-500 text-sm">
                {errors[field.value]}
              </span>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-4 mt-4">
        <button
          className="bg-gray-200 text-gray-700 py-2 px-4 rounded-md shadow-md hover:bg-gray-300"
          onClick={() => navigate('/settings/manageUsers')}>
          Cancel
        </button>
        <button
          className="bg-blue-600 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-700"
          onClick={handleSubmit}>
          Next
        </button>
      </div>
    </div>
  )
}

export default UserForm
