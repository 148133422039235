import React from 'react'
import ReactApexChart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { ISeries } from '../../report.interface'

type ColumnChartProps = {
  data: {
    labels: string[]
    series: ISeries[]
  }
  title: string
}

const ColumnChart: React.FC<ColumnChartProps> = ({ data, title }) => {
  if (!data || !data.labels || !data.series) {
    return <div>Data Loading...</div>
  }

  const options: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: '55%',
        borderRadius: 6,
      },
    },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      title: { text: 'No. of Students' },
      categories: data.labels,
    },
    yaxis: {
      title: { text: `${title.split(' ')[0]}s` },
    },
    fill: { opacity: 1 },
    tooltip: {
      y: {
        formatter: function (val) {
          return val.toString()
        },
      },
    },
  }

  return (
    <div>
      <div id="chart" className="bg-white m-4 p-4 h-[350px] overflow-y-scroll">
        <h1 className="font-bold">{title}</h1>
        <ReactApexChart 
          options={options}
          series={data.series} 
          type="bar"
          />
      </div>
    </div>
  )
}

export default ColumnChart
