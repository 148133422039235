import React, { useEffect, useState } from 'react'
import { OptionItem } from '../report.interface'
import Select from 'react-select'
interface FilterProps {
  label: string
  name: string
  value: string
  options: OptionItem[]
  required?: boolean
  multiple?: boolean
  handleFilterChange: (option: any, data: any) => void
}
const Filter = ({
  label,
  name,
  value,
  options,
  required = false,
  multiple,
  handleFilterChange,
}: FilterProps) => {
  return (
    <div className="flex flex-50 flex-col">
      <label className="form-label">
        {label} {required && <span className="text-red-600">*</span>}
      </label>
      <Select
        name={name}
        value={options.filter(opt =>
          Array.isArray(value) ? value.includes(opt.value) : opt.value === value
        )}
        options={options}
        isDisabled={!multiple && value !== '' && name !== 'learners'}
        isMulti={multiple} 
        onChange={handleFilterChange} 
        className="border-2 rounded-lg"
        isClearable
        isSearchable
      />

    </div>
  )
}
export default Filter
