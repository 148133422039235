import React, { useMemo, useState, useCallback, useEffect } from 'react'
import Filter from './Filter'
import { IDropdownOutletProps, IReportFilter } from '../report.interface'
import { useOutletContext } from 'react-router-dom'
import { getUserRole, removeEmptyValues, getSuperAdmin } from '../utils'
import { API_URL } from '../../../services'
import Select from 'react-select'
interface ReportFilterProps {
  selectedFilters: IReportFilter
  setSelectedFilters: React.Dispatch<React.SetStateAction<IReportFilter>>
  notifyApplyEvent: (data?: any, filter?: any) => any
  notifyResetEvent: () => void
  multiple?: boolean
}
const ReportFilter = ({
  selectedFilters,
  setSelectedFilters,
  notifyApplyEvent,
  notifyResetEvent,
}: ReportFilterProps) => {
  const role = getUserRole()
  console.log(selectedFilters)
  const [assessmentsOptions, setAssessmentOptions] = useState<any[]>([])
  const [filteredDropDownResults, setFilteredDropDownResults] = useState<any>(
    []
  )
  const [allPrograms, setAllPrograms] = useState<any>([])
  const { dropdownOptions }: any = useOutletContext() || {}
  const [overallDropdownOptions, setOverallDropdownOptions] = useState({
    organizations: [],
    departments: [],
    batches: [],
    programs: [],
    assessments: [],
    learners: [],
  })

  const learnerOptions = overallDropdownOptions.learners.map(
    (learner: any) => ({
      value: learner._id,
      label: `${learner.reg_no} - ${learner.email}`,
    })
  )

  useEffect(() => {
    if (filteredDropDownResults) {
      setOverallDropdownOptions({
        organizations: filteredDropDownResults.organizations || [],
        departments: filteredDropDownResults.departments || [],
        batches: filteredDropDownResults.batches || [],
        programs: filteredDropDownResults.programs || [],
        assessments: filteredDropDownResults.assessments || [],
        learners: filteredDropDownResults.distinctLearners || [],
      })
    }
  }, [filteredDropDownResults])

  useEffect(() => {
    filterLearnerData()
  }, [selectedFilters])

  const filterLearnerData = async () => {
    const organization = selectedFilters.organization_id
      ? selectedFilters.organization_id.value
      : ''
    const department = selectedFilters.department
      ? selectedFilters.department.value
      : ''
    const passedout = selectedFilters.passed_out
      ? selectedFilters.passed_out.value
      : ''
    const program = selectedFilters.program_id
      ? selectedFilters.program_id.value
      : ''
    const assessment = selectedFilters.assessments
      ? selectedFilters.assessments?.value
      : ''

    const queryParams = new URLSearchParams({
      organization,
      department,
      passedout,
      program,
      assessment,
      super_admin: getSuperAdmin(),
    }).toString()
    try {
      const response = await fetch(`${API_URL}/analytics_filter?${queryParams}`)
      const res = await response.json()
      setFilteredDropDownResults(res)
      setAllPrograms(res.programDetails)
    } catch (error) {
      console.error('Error:', error)
    }
  }
  const handleLearnerSelect = () => {
    notifyApplyEvent(selectedFilters?.learners?.value, selectedFilters?.program_id?.value)
  }

  const fetchAssessments = (value: any) => {
    const programData: any = allPrograms.find(
      (program: any) => program._id === value
    )
    if (programData) {
      const assessments = programData.modules_list
        .flatMap((module: any) => module.activity_list)
        .filter((activity: any) => activity.type === 'assessment')
        .map((activity: any) => ({
          label: activity.data.test_name,
          value: activity._id,
        }))
      setAssessmentOptions(assessments)
    }
  }

  const handleFilterChange = (selectedOption: any, actionMeta: any) => {
    const name = actionMeta.name
    const value = Array.isArray(selectedOption)
      ? selectedOption.map(opt => opt.value)
      : selectedOption?.value

    setSelectedFilters((prevFilters: any) => {
      if (JSON.stringify(prevFilters[name]?.value) === JSON.stringify(value)) {
        return prevFilters
      }

      return {
        ...prevFilters,
        [name]: { ...prevFilters[name], value },
      }
    })

    if (name === 'program_id') {
      fetchAssessments(value)
    }
  }

  const isDisabled = useMemo(() => {
    const filterWithValues = removeEmptyValues(selectedFilters, true)

    const requiredFieldEmpty = Object.values(selectedFilters).some(
      field => field.required && field.value === ''
    )
    return (
      requiredFieldEmpty ||
      Object.keys(filterWithValues).length === 0
    )
  }, [selectedFilters])

  return (
    <div className="flex flex-col gap-4 m-4">
      <div className="flex flex-wrap gap-4 justify-between">
        {role === 'super_admin' &&
          selectedFilters.organization_id !== undefined && (
            <Filter
              label="Organization"
              name="organization_id"
              value={selectedFilters.organization_id.value}
              required={selectedFilters.organization_id.required}
              options={overallDropdownOptions.organizations}
              handleFilterChange={handleFilterChange}
            />
          )}
        {selectedFilters.department !== undefined && (
          <Filter
            label="Department"
            name="department"
            value={selectedFilters.department.value}
            required={selectedFilters.department.required}
            options={overallDropdownOptions.departments}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.passed_out !== undefined && (
          <Filter
            label="Batch"
            name="passed_out"
            value={selectedFilters.passed_out.value}
            required={selectedFilters.passed_out.required}
            options={overallDropdownOptions.batches}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.program_id !== undefined && (
          <Filter
            label="Program"
            name="program_id"
            multiple={selectedFilters.hasOwnProperty('learners')}
            value={selectedFilters.program_id.value}
            required={selectedFilters.program_id.required}
            options={overallDropdownOptions.programs}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.assessments !== undefined && (
          <Filter
            label="Assessments"
            name="assessments"
            value={selectedFilters.assessments.value}
            required={selectedFilters.assessments.required}
            options={assessmentsOptions}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.sections !== undefined && (
          <Filter
            label="Sections"
            name="sections"
            value={selectedFilters.sections.value}
            required={selectedFilters.sections.required}
            options={dropdownOptions.sections}
            handleFilterChange={handleFilterChange}
          />
        )}
        {selectedFilters.learners !== undefined && (
          <Filter
            label="Enter Learner ID"
            name="learners"
            value={selectedFilters.learners.value}
            multiple={false}
            required={selectedFilters.learners.required}
            options={learnerOptions}
            handleFilterChange={handleFilterChange}
          />
        )}
      </div>
      <div className="flex flex-end justify-end gap-4">
        <button
          onClick={() => {
            notifyResetEvent()
          }}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded shadow">
          Reset
        </button>
        <button
          onClick={() => {
            selectedFilters.hasOwnProperty('learners') ? handleLearnerSelect() :
            notifyApplyEvent()
          }}
          disabled={isDisabled}
          className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded shadow ${
            isDisabled ? 'bg-gray-200 hover:bg-gray-200 cursor-not-allowed' : ''
          }`}>
          Apply
        </button>
      </div>
    </div>
  )
}

export default ReportFilter
