import React from "react";
import Chart from "react-apexcharts";

type HistogramChartProps = {
  chartData: {
    labels: any[]
    series: number[]
  }
}

const ProgramScoreChart: React.FC<HistogramChartProps> = ({chartData} ) =>{

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "bar" as const,
      stacked: false,
      background: "#ffffff",
    },    
    xaxis: {
      categories: chartData.labels,
      title: { text: "Score Range" },
    },
    yaxis: {
      min: 0,
      max: 50, 
      title: { text: "Number of Students" },
    }, 
    plotOptions: {
      bar: {
        columnWidth: "100%", 
        barHeight: "100%", 
        distributed: false, 
      },
    },    
    dataLabels: {
      enabled: false,
    },
    colors: ["#4285F4"], 
  };
  
  return <Chart options={options} series={chartData.series} type="bar" height={400} />;
};


export default ProgramScoreChart;
