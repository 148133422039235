import React, { useEffect, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { API_URL } from '../../services/index'
import axios from 'axios'
import { getDateFromNow } from '../utils/dateUtils' // Adjust the path as per your file structure

interface FilterSectionProps {
  updateFilteredList: (list: any) => void
}
interface IFilterValues {
  programName?: string
  programStatus?: string
  startDate?: string
  endDate?: string
  createdBy?: string
  orgName?: string
  createdWithin?: string
  dateRange?: string
}

export default function FilterSection({
  updateFilteredList,
}: FilterSectionProps) {
  const [allProgramsList, setAllProgramsList] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [admins, setAdmins] = useState<any[]>([])
  const [filterValues, setFilterValues] = useState<IFilterValues>({
    programName: '',
    programStatus: '',
    startDate: '',
    endDate: '',
    createdBy: '',
    orgName: '',
    createdWithin: '',
  })

  const [programs, setPrograms] = useState<any[]>([])
  const [total, setTotal] = useState<number>(0)

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setFilterValues({
      ...filterValues,
      [name]: value,
    })

    // Call handleFilterChange to apply filters as user types
    handleFilterChange({
      ...filterValues,
      [name]: value,
    })
  }

  const resetFilter = () => {
    setFilterValues({
      programName: '',
      programStatus: '',
      startDate: '',
      endDate: '',
      createdBy: '',
      orgName: '',
      createdWithin: '',
    })
    setFilteredItems(allProgramsList)
  }

  useEffect(() => {
    updateFilteredList(filteredItems)
  }, [filteredItems])

  const fetchProgramsList = async () => {
    try {
      const response = await fetch(`${API_URL}/allprograms`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      if (!response.ok) {
        throw new Error('Failed to fetch programs')
      }

      const result = await response.json()
      // console.log(result);

      setAllProgramsList(result.all_programs)
      setFilteredItems(result.all_programs)
      setPrograms(result.all_programs)
      setTotal(result.all_programs.length)
    } catch (error) {
      console.error(error)
    }
  }

  const fetchAdminsList = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/sub_admin/FetchAll`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )

      if (response.status === 200) {
        const superAdminName = localStorage.getItem('user')
          ? JSON.parse(localStorage.getItem('user') || '{}').admin_name
          : ''
        const adminsList = response.data.data
        setAdmins([
          { admin_name: superAdminName, _id: 'super_admin' },
          ...adminsList,
        ])
      } else {
        console.error('Failed to fetch subadmins:', response.data.message)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchProgramsList()
    fetchAdminsList()
  }, [])

  const handleFilterChange = (filterValuesObj: IFilterValues) => {
    console.log('Filter values:', filterValuesObj)

    const [startOfWeek, endOfWeek] = getDateFromNow('thisWeek')
    console.log('Start of week:', startOfWeek)
    console.log('End of week:', endOfWeek)

    const startDate = filterValuesObj.startDate
      ? new Date(filterValuesObj.startDate)
      : null
    const endDate = filterValuesObj.endDate
      ? new Date(filterValuesObj.endDate)
      : null
    console.log('Parsed start date:', startDate)
    console.log('Parsed end date:', endDate)

    setFilteredItems(
      allProgramsList.filter((item: any) => {
        const startDateValid =
          !startDate || new Date(item.program_start_date) >= startDate
        const endDateValid =
          !endDate || new Date(item.program_end_date) <= endDate

        console.log(startDateValid, endDateValid)

        let createdWithinValid: boolean = true
        if (filterValuesObj.createdWithin === 'thisWeek') {
          createdWithinValid =
            new Date(item.created_at) >= startOfWeek &&
            new Date(item.created_at) <= endOfWeek
        } else if (filterValuesObj.createdWithin) {
          const [customStartDate, customEndDate] = getDateFromNow(
            filterValuesObj.createdWithin
          )
          createdWithinValid =
            new Date(item.created_at) >= customStartDate &&
            new Date(item.created_at) <= customEndDate
        }

        return (
          (!filterValuesObj.programName ||
            item.program_name
              .trim()
              .toUpperCase()
              .includes(filterValuesObj.programName.trim().toUpperCase())) &&
          (!filterValuesObj.createdBy ||
            item.created_by.trim().toUpperCase() ===
              filterValuesObj.createdBy.trim().toUpperCase()) &&
          (!filterValuesObj.orgName ||
            item.orgName === filterValuesObj.orgName) &&
          (filterValuesObj.programStatus === '' ||
            item.status.toString() === filterValuesObj.programStatus) &&
          startDateValid &&
          endDateValid &&
          createdWithinValid
        )
      })
    )
  }

  // Extract unique created_by values from programs
  const createdBySet = new Set(programs.map(item => item.created_by))
  const createdByOptions = Array.from(createdBySet)

  return (
    <div className="bg-white shadow-2xl flex flex-col gap-2 rounded-md p-4">
      <div className="layer-3 flex font-sans justify-between">
        <div className="flex gap-10">
          <div className="flex items-start flex-col">
            <label
              htmlFor="programName"
              className="font-medium mr-2 text-right">
              Program Name
            </label>
            <input
              type="text"
              placeholder="Enter Program Name"
              id="programName"
              name="programName"
              value={filterValues.programName}
              className="px-3 py-2 border border-2 border-gray-500 w-[20rem] mt-4 outline-1 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500 placeholder:text-gray-500"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
      <form className="flex flex-col gap-2 mt-4">
        <div className="flex flex-row items-center justify-start gap-16">
          <div className="flex items-start flex-col">
            <label htmlFor="programStatus" className="">
              Program Status:
            </label>
            <select
              id="programStatus"
              name="programStatus"
              value={filterValues.programStatus}
              className="px-3 py-2 border border-2 border-gray-500 mt-4 rounded-md text-center text-gray-500 focus:outline-none focus:ring-1 focus:ring-green-500"
              onChange={handleInputChange}>
              <option value="">All</option>
              <option value="0">Draft</option>
              <option value="1">Live</option>
            </select>
          </div>
          <div className="flex  items-start flex-col">
            <label htmlFor="createdBy" className="text-center">
              Created by:
            </label>
            <select
              id="createdBy"
              name="createdBy"
              value={filterValues.createdBy}
              className="px-3 py-2 border border-2 text-gray-500 border-gray-500 mt-4 placeholder:text-center rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
              onChange={handleInputChange}>
              <option value="">All</option>
              {admins.map(admin => (
                <option key={admin._id} value={admin._id}>
                  {admin.admin_name}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center text-center flex-col">
            <label htmlFor="endDate" className="">
              Date:
            </label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={filterValues.endDate}
              className="px-3 py-2 border border-2 border-gray-500 mt-4 uppercase text-gray-500 rounded-md focus:outline-none focus:ring-1 focus:ring-green-500"
              onChange={handleInputChange}
            />
          </div>
          <div className="flex text-center items-start flex-col">
            <label htmlFor="createdWithin" className="">
              Created Within:
            </label>
            <select
              id="createdWithin"
              name="createdWithin"
              value={filterValues.createdWithin}
              className="px-3 py-2 w-[7rem] border border-2 border-gray-500 mt-4 rounded-md text-center text-gray-500 focus:outline-none focus:ring-1 focus:ring-green-500"
              onChange={handleInputChange}>
              <option value="">Select</option>
              <option value="thisWeek">This Week</option>
              <option value="thisMonth">This Month</option>
              <option value="6months">6 Months</option>
              <option value="1year">1 Year</option>
            </select>
          </div>
        </div>
        <div className="flex justify-end space-x-2 mt-4">
          <button
            type="button"
            className="px-3 py-2 hover:bg-[#f38888] rounded-md"
            onClick={resetFilter}>
            Reset
          </button>
          <button
            type="button"
            className="px-3 py-2 bg-[#009000] text-white hover:bg-[#276749] rounded-md"
            onClick={() => handleFilterChange(filterValues)}>
            Apply
          </button>
        </div>
      </form>
    </div>
  )
}
