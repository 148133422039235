export interface PermissionData {
  label: string
  items?: PermissionData[]
  value: string
}
export const PERMISSION_DATA: PermissionData[] = [
  {
    label: 'Program Management',
    value: 'manage_program_page',
    items: [
      { label: 'Create Program', value: 'create_program' },
      { label: 'View Program', value: 'view_program' },
      { label: 'Edit Program', value: 'edit_program' },
      { label: 'Delete Program', value: 'delete_program' },
      {
        label: 'Learners Assign/Unassign Page',
        value: 'assign_program_to_learners_page',
      },
      {
        label: 'Assign/Unassign Access',
        value: 'assign_program_to_learners',
      },
    ],
  },

  {
    label: 'Upload Report',
    value: 'manage_reports_page',
    items: [{ label: 'Upload Reports', value: 'upload_reports' }],
  },

  {
    label: 'List Assessment',
    value: 'manage_assessment_page',
    items: [
      { label: 'Add/Edit Assessment', value: 'add_assessment' },
      { label: 'View Assessment', value: 'view_assessment' },
    ],
  },

  {
    label: 'Learner Management',
    value: 'manage_learners_page',
    items: [
      // { label: 'Learners', value: 'learners' },
      { label: 'Enroll Learners', value: 'enroll_learners' },
      { label: 'Manage Learners', value: 'manage_learners' },
      {
        label: 'Extend Validity, Manage Access, Delete Learners',
        value: 'manage_learners_access',
      },
      {
        label: 'Download Report',
        value: 'download_learners_list',
      },
    ],
  },

  {
    label: 'Analytics Management',
    value: 'manage_analytics_page',
    items: [
      {
        label: 'View Progress Analytics',
        value: 'view_progress_analytics',
      },
      {
        label: 'View Department Level Analytics',
        value: 'view_department_level_analytics',
      },    
      {
        label: 'View Program Level Analytics',
        value: 'view_program_level_analytics',
      },
      {
        label: 'View Assessment Level Analytics',
        value: 'view_assessment_level_analytics',
      },
      {
        label: 'View Learner Level Analytics',
        value: 'view_learner_level_analytics',
      },
      {
        label: 'Download Reports',
        value: 'download_reports',
      },
    ],
  },
  {
    label: 'Global Settings Management',
    value: 'manage_settings_page',
    items: [
      {
        label: 'View Profile',
        value: 'view_profile',
      },
      {
        label: 'Manage Admin',
        value: 'manage_users',
      },
      {
        label: 'Remove Admin',
        value: 'remove_users',
      },
      {
        label: 'List Admin',
        value: 'view_users',
      },
      // {
      //   label: 'Manage BU',
      //   value: 'manage_bu',
      // },
      // {
      //   label: 'Remove BU',
      //   value: 'remove_bu',
      // },
      // {
      //   label: 'List BU',
      //   value: 'view_bu',
      // },
      // {
      //   label: 'White Labelling',
      //   value: 'white_labelling',
      // },
      {
        label: 'View Calendar',
        value: 'view_calendar',
      },
    ],
  },
]

// TODO
// manage_users, remove_users, view_users only from admin's organization
