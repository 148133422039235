import React, { useCallback, useState, useMemo } from 'react'
import { API_URL } from '../../../../services'
import {
  IReportFilter,
  IReportOverallAssessmentData,
  SortChartType,
} from '../../report.interface'
import ReportFilter from '../../components/ReportFilter'
import { removeEmptyValues } from '../../utils'
import BoxPlot from '../../components/BoxPlot/BoxPlot'
import RadioButton from '../../components/RadioButton'
import ColumnChart from '../../components/ColumnChart/ColumnChart'
import ProgramReportTable from './ProgramReportTable'
import PieChart from '../../components/PieChart/PieChart'
import ProgramScoreChart from './ProgramScoreChart'

const formFields: IReportFilter = {
  organization_id: { value: '' },
  department: { value: '' },
  passed_out: { value: '' },
  program_id: { value: '', required: true },
}

const defaultResponse: IReportOverallAssessmentData = {
  learner_details: [],
  report_details: [],
  program_details: {},
  programModulesScore: {
    module_percentile: {
      labels: [],
      series: [],
    },
    program_scores_list: {
      labels: [],
      series: [],
    },
  },
  program_status_counts: {
    program_series: {
      labels: [],
      series: [],
    },
  },
  activity_status_counts: {
    labels: [],
    series: [],
  },
  performance_analytics: {
    data: [],
    programs: [],
  },
}

const ProgramLevel = () => {
  const [reportData, setReportData] = useState<IReportOverallAssessmentData>(
    defaultResponse
  )
  const [selectedFilters, setSelectedFilters] = useState<IReportFilter>(
    formFields
  )
  const [selected, setSelected] = useState<SortChartType>('latest')
  const [viewTable, setViewTable] = useState(false)

  const handleResetEvent = () => {
    setSelectedFilters(formFields)
    setViewTable(false)
  }

  const fetchReportData = useCallback(async () => {
    try {
      const updatedObj = {
        ...selectedFilters,
        filter_type: { value: selected },
      }
      const query = new URLSearchParams(removeEmptyValues(updatedObj))
      const response = await fetch(
        `${API_URL}/super_admin_overall_level_assessment_performance/?${query}`
      )

      const result = await response.json()
      setReportData(result)
      setViewTable(false)
    } catch (error) {
      console.error('Error fetching learner progress data:', error)
    }
  }, [selectedFilters, selected])

  return (
    <div className="w-full">
      <ReportFilter
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        notifyApplyEvent={fetchReportData}
        notifyResetEvent={handleResetEvent}
      />

      <div className="flex">
        <div className="w-1/2">
          <PieChart
            chartData={reportData.program_status_counts.program_series}
            title="Program Overall Progress Status"
          />
        </div>
        <div className="w-1/2">
          <PieChart
            chartData={reportData.programModulesScore.module_percentile}
            title="Percentile Analysis"
          />
        </div>
      </div>
      <div className="w-full flex flex-col">
        <ColumnChart
          data={reportData?.activity_status_counts}
          title={'Module Status'}
        />
      </div>
      <div>
        <ProgramScoreChart
          chartData={
            reportData.programModulesScore.program_scores_list
          }></ProgramScoreChart>
      </div>
      <div className="w-full">
        <ProgramReportTable
          learners={reportData.learner_details}
          reports={reportData.report_details}
          selectedProgram={reportData.program_details}
          selectedProgramId={selectedFilters?.program_id?.value}
        />
      </div>
    </div>
  )
}

export default ProgramLevel
